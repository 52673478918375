const swiperMain = new Swiper(".swiper-main", {
    wrapperClass: "swiper-main-wrapper",
    slideClass: "swiper-main-slide",
    loop: true,
    slidesPerView: 1,
    pagination: {
        el: '.body-counter-slide',
        type: "bullets",
        bulletActiveClass: "count-slide-main-active",
        bulletClass: "count-slide-main"
    },

    // Navigation arrows
    navigation: {
        nextEl: '#btn-next-slide-main',
        prevEl: '#btn-prev-slide-main',
    },
})

const swiper = new Swiper('.swiper-events', {
    // Optional parameters
    loop: true,
    slidesPerView: $("body").width() >= 1400 ? 4 : 3,
    spaceBetween: 20,

    // If we need pagination
    pagination: {
        el: '.body-counter-slide',
        type: "bullets",
        bulletActiveClass: "count-slide-event-active",
        bulletClass: "count-slide-event"
    },

    // Navigation arrows
    navigation: {
        nextEl: '#btn-next-event',
        prevEl: '#btn-prev-event',
    },
});
